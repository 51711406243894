import React from 'react'
import { graphql } from 'gatsby'
import Layout from "../components/Layout";
import SimplePageTemplate from "@trzyrybki/shared-ui/templates/simple-page";

const SimplePage = ({ data, pageContext }) => {
  const { frontmatter, html } = data.markdownRemark

  return (
    <Layout menu={JSON.parse(pageContext.menu)} footer={JSON.parse(pageContext.footer)}>
      <SimplePageTemplate
        title={frontmatter.title}
        html={html}
      />
    </Layout>
  )
}

export default SimplePage

export const pageQuery = graphql`
  query SimplePageTemplate($id: String) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
      }
      html
    }
  }
`

import React from 'react'
import PropTypes from 'prop-types'

export const SimplePageTemplate = ({
    title,
    html
}) => {
    return (
      <section className="portfolio-details-gallery-area">
        <div className="background">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="portfolio-details-gallery-content simple-page-content">
                    <h2 className="absolute-border">{title}</h2>
                    <div dangerouslySetInnerHTML={{ __html: html }}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
};

SimplePageTemplate.propTypes = {
  title: PropTypes.string,
  html: PropTypes.string
}

export default SimplePageTemplate;

// const SimplePage = ({ data, pageContext }) => {
//   const { frontmatter, html } = data.markdownRemark
//
//   return (
//     <Layout menu={JSON.parse(pageContext.menu)} footer={JSON.parse(pageContext.footer)}>
//       <SimplePageTemplate
//         title={frontmatter.title}
//         html={html}
//       />
//     </Layout>
//   )
// }
//
// SimplePage.propTypes = {
//   data: PropTypes.shape({
//     markdownRemark: PropTypes.shape({
//       frontmatter: PropTypes.object,
//       html: PropTypes.string
//     }),
//   }),
// }
//
// export default SimplePage
//
// export const pageQuery = graphql`
//   query SimplePageTemplate($id: String) {
//     markdownRemark(id: { eq: $id }) {
//       frontmatter {
//         title
//       }
//       html
//     }
//   }
// `
